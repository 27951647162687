<template>
  <ContentContainer>
    <ion-progress-bar v-if="loading" type="indeterminate" />

    <div v-if="error && !loading">
      {{ error }}
    </div>

    <div v-if="!error && !loading">
      {{ $t('membershipsuccessfull') }}
    </div>
  </ContentContainer>
</template>

<script>
    import { mapGetters } from "vuex";
    import { CURRENT_USER_GETTER } from "@/store/store-getters";
    import { STRIPE_API_KEY } from "@/configs";

    export default {
      data() {
        return {
          loading: true,
          error: ''
        }
      },
      computed: {
        ...mapGetters({user: CURRENT_USER_GETTER}),
      },
      created() {
        const stripe = window.Stripe(STRIPE_API_KEY)

        stripe.createSource({
          type: 'sepa_debit',
          sepa_debit: {
            ideal: this.$route.query.source,
          },
          owner: {
            name: `${this.user.firstname} ${this.user.last_name}`,
            email: this.user.email
          },
          currency: 'eur',
        }).then(() => {
          this.loading = false;
        }).catch(() => {
          this.loading = false;
          this.error = 'U heeft betaald voor de eerste keer, maar er ging iets mis met het aanmaken van de SEPA machtiging.'
        })
      }
    }
</script>
